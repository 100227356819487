* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Encode Sans Expanded', sans-serif;
}

html, body, #root {
  height: 100%;
}

body {
  background-color: #1A1A1D;
  color: white;
}

.mantine-1w07r5r {
  color: inherit;
}

.mantine-1yr0jao {
  padding: 0;
}

.mantine-1x8cqb4, .mantine-1kdabaz, .mantine-us7vc8{
  font-size: 16px;
}
